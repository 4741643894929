import React, { useState } from "react";

import { db } from "../../../firebaseConfig";
import Postave from "../../../Assets/Images/Ostalo/Postave.png";
import Player from "../../../Assets/Images/Ostalo/Player.png";
import NKPodsusedBijelaBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Bg.jpg";
import NKPodsusedBijelaPhoneBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Phone-Bg.jpg";
import Main from "../../Pages/Home/Main";
import { useNavigate } from "react-router-dom";
import { setDoc, doc, getDoc } from "firebase/firestore";

// Popis klubova
const teams = [
  "NK Dragonožec",
  "NK Čehi",
  "NK Čulinec",
  "NK Ćiro Academy",
  "NK Podsused",
  "NK Hrašće",
  "NK Zagreb 041",
  "NK Sloga Zagreb",
  "NK Strmec Zagreb",
  "NK Ivanja Reka",
  "NK Gavran 2003",
  "NK Croatia 98",
];

// Popis igrača
const players = [
  "G. Bašić",
  "T. Ivanuš",
  "A. Boras",
  "K. Borko",
  "M. Perković",
  "K. Minković",
  "R. Budiselić",
  "M. Stunja",
  "M. Vodopija",
  "D. Mihoković",
  "M. Sedmak",
  "C | J. Gotal",
  "M. Knežević",
  "L. Moguljak",
  "K. Mihelčić",
  "S. Miloš",
  "J. Jambrek",
  "J. Mesić",
  "L. Ivčić",
  "J. Jurić",
  "T. Posavec",
  "F. Đuričić",
  "L. Nerat",
];

// Popis pozicija
const positions = [
  { label: "Golman", value: "GK" },
  { label: "Lijevi bek", value: "LB" },
  { label: "Lijevi stoper", value: "LCB" },
  { label: "Stoper (centar)", value: "CB" },
  { label: "Desni stoper", value: "RCB" },
  { label: "Desni bek", value: "RB" },
  { label: "Zadnji vezni (lijevi)", value: "LCDM" },
  { label: "Zadnji vezni (desni)", value: "RCDM" },
  { label: "Desni vezni", value: "RCM" },
  { label: "Lijevi vezni", value: "LCM" },
  { label: "Vezni (centar)", value: "CM" },
  { label: "Lijevo krilo", value: "LW" },
  { label: "Desno krilo", value: "RW" },
  { label: "Napadač", value: "ST" },
  { label: "Napadač (lijevi)", value: "LST" },
  { label: "Napadač (desni)", value: "RST" },
  { label: "Vezni (prednji)", value: "CF" },
];

const AddMatches = () => {
  const [homeTeam, setHomeTeam] = useState("");
  const [awayTeam, setAwayTeam] = useState("");
  const [round, setRound] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [location, setLocation] = useState("");
  const [homeScore, setHomeScore] = useState("");
  const [awayScore, setAwayScore] = useState("");
  const [events, setEvents] = useState([]);
  const [lineup, setLineup] = useState([]);
  const [homeTeamLogo, setHomeTeamLogo] = useState("");
  const [awayTeamLogo, setAwayTeamLogo] = useState("");
  const [benchPlayers, setBenchPlayers] = useState([]);
  const [selectedBenchPlayer, setSelectedBenchPlayer] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  // Add a new event
  const handleAddEvent = () => {
    setEvents([...events, { player: "", time: "", type: "", team: "" }]);
  };

  // Fetch team logo from Firestore
  const fetchTeamLogo = async (teamName) => {
    try {
      const docRef = doc(db, "podsused", "podsused");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const kluboviData = docSnap.data().klubovi;
        const team = kluboviData.find((k) => k.klub === teamName); // Use 'klub' for team name

        if (team) {
          return team.logoUrl;
        } else {
          setMessage("Nema logotipa za odabrani tim");
          return null;
        }
      } else {
        setMessage("Nema podataka o klubovima");
        return null;
      }
    } catch (error) {
      setMessage("Greška prilikom dohvaćanja logotipa");
      console.error(error);
    }
  };

  // Update logo when a team is selected
  const handleHomeTeamChange = async (e) => {
    const selectedTeam = e.target.value;
    setHomeTeam(selectedTeam);
    const logoUrl = await fetchTeamLogo(selectedTeam);
    setHomeTeamLogo(logoUrl);
  };

  const handleAwayTeamChange = async (e) => {
    const selectedTeam = e.target.value;
    setAwayTeam(selectedTeam);
    const logoUrl = await fetchTeamLogo(selectedTeam);
    setAwayTeamLogo(logoUrl);
  };

  // Remove an event from the events list
  const handleRemoveEvent = (index) => {
    const newEvents = events.filter((_, i) => i !== index);
    setEvents(newEvents);
  };

  // Add a player to the lineup
  const handleAddPlayer = () => {
    setLineup([...lineup, { player: "", position: "" }]);
  };

  // Remove a player from the lineup
  const handleRemovePlayer = (index) => {
    const newLineup = lineup.filter((_, i) => i !== index);
    setLineup(newLineup);
  };

  // Add a bench player
  const handleAddBenchPlayer = () => {
    if (selectedBenchPlayer) {
      setBenchPlayers([
        ...benchPlayers,
        { name: selectedBenchPlayer, pos: "Bench" },
      ]);
      setSelectedBenchPlayer("");
    }
  };

  // Remove a bench player
  const handleRemoveBenchPlayer = (index) => {
    const newBenchPlayers = benchPlayers.filter((_, i) => i !== index);
    setBenchPlayers(newBenchPlayers);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!homeTeam || !awayTeam || !round || !date || !time || !location) {
      alert("Sva polja moraju biti popunjena!");
      return;
    }

    const matchData = {
      homeTeam,
      awayTeam,
      round,
      date,
      time,
      location,
      result: `${homeScore}-${awayScore}`,
      events,
      lineup,
      benchPlayers,
    };

    try {
      const docRef = doc(db, "podsused", "podsused");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const existingData = docSnap.data();
        let updatedMatches = [...(existingData.utakmice || [])];

        // Provjera postoji li utakmica za odabrano kolo
        const matchIndex = updatedMatches.findIndex((m) => m.round === round);

        if (matchIndex !== -1) {
          // Ažuriraj postojeću utakmicu
          updatedMatches[matchIndex] = matchData;
        } else {
          // Dodaj novu utakmicu
          updatedMatches.push(matchData);
        }

        // Spremi ažurirane utakmice
        await setDoc(docRef, { utakmice: updatedMatches }, { merge: true });
      } else {
        // Ako dokument ne postoji, stvori ga s prvom utakmicom
        await setDoc(docRef, { utakmice: [matchData] });
      }

      alert("Utakmica je uspješno spremljena!");
      resetForm(); // Resetiraj formu nakon spremanja
    } catch (error) {
      alert("Došlo je do greške. Pokušajte ponovno.");
      console.error(error);
    }
  };

  // Reset form fields
  const resetForm = () => {
    setHomeTeam("");
    setAwayTeam("");
    setRound("");
    setDate("");
    setTime("");
    setLocation("");
    setHomeScore("");
    setAwayScore("");
    setEvents([]);
    setLineup([]);
    setBenchPlayers([]);
  };

  // Fetch existing match data for a specific round
  const fetchMatchData = async (round) => {
    const matchDocRef = doc(db, "podsused", "podsused");
    const matchDoc = await getDoc(matchDocRef);

    if (matchDoc.exists()) {
      const matchData = matchDoc.data();
      const match = matchData.utakmice.find((m) => m.round === round);
      return match || null;
    }
    return null;
  };

  // Load match data when a round is selected
  const handleRoundChange = async (e) => {
    const selectedRound = e.target.value;
    setRound(selectedRound);

    if (selectedRound) {
      const matchData = await fetchMatchData(selectedRound);
      if (matchData) {
        // Populate form fields with existing data
        setHomeTeam(matchData.homeTeam);
        setAwayTeam(matchData.awayTeam);
        setDate(matchData.date);
        setTime(matchData.time);
        setLocation(matchData.location);
        setHomeScore(matchData.result.split("-")[0]);
        setAwayScore(matchData.result.split("-")[1]);
        setEvents(matchData.events);
        setLineup(matchData.lineup);
        setBenchPlayers(matchData.benchPlayers);
      }
    }
  };

  return (
    <>
      <Main
        desktopBackground={NKPodsusedBijelaBg}
        phoneBackground={NKPodsusedBijelaPhoneBg}
        title="DODAJ UTAKMICU"
      />
      <div className="section">
        <button
          className="go-back-btn"
          onClick={() => navigate("/admin/dashboard")}
        >
          Vrati se nazad
        </button>
        <form className="addMatches-form" onSubmit={handleSubmit}>
          <div>
            <label>KOLO:</label>
            <select value={round} onChange={handleRoundChange}>
              <option value="">KOLO</option>
              {Array.from({ length: 24 }, (_, i) => (
                <option key={i} value={`${i + 1}. KOLO`}>
                  {i + 1}. KOLO
                </option>
              ))}
            </select>
            {message && <p className="message">{message}</p>}
          </div>
          <div className="club-flex">
            <div>
              <label>Domaći Tim</label>
              <select value={homeTeam} onChange={handleHomeTeamChange}>
                <option value="" disabled>
                  Domaći tim
                </option>
                {teams.map((team) => (
                  <option key={team} value={team}>
                    {team}
                  </option>
                ))}
              </select>
              {homeTeamLogo && (
                <img src={homeTeamLogo} alt="Home team logo" width={50} />
              )}
            </div>

            <div>
              <label>Gostujući Tim:</label>
              <select value={awayTeam} onChange={handleAwayTeamChange}>
                <option value="" disabled>
                  Gostujući tim
                </option>
                {teams.map((team) => (
                  <option key={team} value={team}>
                    {team}
                  </option>
                ))}
              </select>
              {awayTeamLogo && (
                <img src={awayTeamLogo} alt="Away team logo" width={50} />
              )}
            </div>
          </div>
          <h5>Rezultat:</h5>
          <div className="club-flex">
            <div>
              <input
                className="result"
                type="number"
                value={homeScore}
                onChange={(e) => setHomeScore(e.target.value)}
                placeholder="0"
              />
            </div>
            <div>
              <input
                className="result"
                type="number"
                value={awayScore}
                onChange={(e) => setAwayScore(e.target.value)}
                placeholder="0"
              />
            </div>
          </div>
          <div>
            <label>Datum:</label>
            <input
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Vrijeme:</label>
            <input
              type="time"
              value={time}
              onChange={(e) => setTime(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Lokacija:</label>
            <input
              type="text"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              placeholder="Lokacija"
              required
            />
          </div>

          <h5>Dodaj događaje:</h5>
          {events.map((event, index) => (
            <div className="match-events" key={index}>
              <input
                type="text"
                placeholder="Igrač"
                value={event.player}
                onChange={(e) =>
                  setEvents((prev) => {
                    const newEvents = [...prev];
                    newEvents[index].player = e.target.value;
                    return newEvents;
                  })
                }
              />
              <input
                type="text"
                placeholder="Vrijeme"
                value={event.time}
                onChange={(e) =>
                  setEvents((prev) => {
                    const newEvents = [...prev];
                    newEvents[index].time = e.target.value;
                    return newEvents;
                  })
                }
              />
              <select
                value={event.type}
                onChange={(e) =>
                  setEvents((prev) => {
                    const newEvents = [...prev];
                    newEvents[index].type = e.target.value;
                    return newEvents;
                  })
                }
              >
                <option value="">Događaj</option>
                <option value="Gol">Gol</option>
                <option value="Žuti karton">Žuti karton</option>
                <option value="Crveni karton">Crveni karton</option>
                <option value="Povreda">Povreda</option>
              </select>
              <select
                value={event.team}
                onChange={(e) =>
                  setEvents((prev) => {
                    const newEvents = [...prev];
                    newEvents[index].team = e.target.value;
                    return newEvents;
                  })
                }
              >
                <option value="">Tim</option>
                <option value="Domaći">Domaći</option>
                <option value="Gostujući">Gostujući</option>
              </select>
              <button
                className="remove-btn"
                type="button"
                onClick={() => handleRemoveEvent(index)}
              >
                Ukloni
              </button>
            </div>
          ))}
          <button className="add-btn" type="button" onClick={handleAddEvent}>
            Dodaj Događaj
          </button>

          <h3>Dodaj igrače u postavu:</h3>
          {lineup.map((player, index) => (
            <div className="add-player" key={index}>
              <select
                value={player.player}
                onChange={(e) => {
                  const newLineup = [...lineup];
                  newLineup[index].player = e.target.value;
                  setLineup(newLineup);
                }}
              >
                {" "}
                <option value="">Odaberi igrača</option>
                {players.map((player) => (
                  <option key={player} value={player}>
                    {player}
                  </option>
                ))}
              </select>
              <select
                value={player.position}
                onChange={(e) =>
                  setLineup((prev) => {
                    const newLineup = [...prev];
                    newLineup[index].position = e.target.value;
                    return newLineup;
                  })
                }
              >
                <option value="">Pozicija</option>
                {positions.map((pos) => (
                  <option key={pos.value} value={pos.value}>
                    {pos.label}
                  </option>
                ))}
              </select>
              <button
                className="remove-btn"
                type="button"
                onClick={() => handleRemovePlayer(index)}
              >
                Ukloni
              </button>
            </div>
          ))}
          <button className="add-btn" type="button" onClick={handleAddPlayer}>
            Dodaj Igrača
          </button>

          {/* Ovdje dodaj kod za prikaz postave */}
          <div className="lineups">
            <div className="lineups-stadium">
              <img src={Postave} alt="Teren" loading="lazy" />
              <div className="players">
                {lineup.map((player, index) => (
                  <div key={index} className={`player ${player.position}`}>
                    <img
                      src={Player}
                      alt={player.player}
                      className="player-img"
                      loading="lazy"
                    />
                    <span>{player.player}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="bench-players">
            {benchPlayers &&
              benchPlayers.length > 0 &&
              benchPlayers.map((player, index) => (
                <div key={index} className={`player ${player.pos}`}>
                  <img
                    src={Player} // Slika igrača, koristiš placeholder ili pravi put
                    alt={player.name}
                    className="player-img"
                    loading="lazy"
                    onClick={() => handleRemoveBenchPlayer(index)} // Click handler
                    style={{ cursor: "pointer" }} // Change cursor to indicate clickable
                  />
                  <span>{player.name}</span>
                </div>
              ))}
          </div>
          <h3>Dodaj klupu:</h3>
          <select
            value={selectedBenchPlayer}
            onChange={(e) => setSelectedBenchPlayer(e.target.value)}
          >
            <option value="">Odaberi zamjenu</option>
            {players
              .filter(
                (player) =>
                  !lineup.map((p) => p.player).includes(player) &&
                  !benchPlayers.map((bp) => bp.name).includes(player)
              )
              .map((player) => (
                <option key={player} value={player}>
                  {player}
                </option>
              ))}
          </select>
          <button
            className="add-btn"
            type="button"
            onClick={handleAddBenchPlayer}
          >
            Dodaj na Klupu
          </button>
          <button className="add-btn publish" type="submit">
            Spremi
          </button>
        </form>
      </div>
    </>
  );
};

export default AddMatches;

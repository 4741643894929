import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { db } from "../../../firebaseConfig";
import { doc, getDoc } from "firebase/firestore";

const News = ({ slug }) => {
  const [newsData, setNewsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState(""); // Dodano stanje za poruke

  useEffect(() => {
    const fetchNewsData = async () => {
      try {
        // Fetch the specific document containing the novosti array
        const docRef = doc(db, "podsused", "podsused"); // Reference to the specific document
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          const newsList = data.novosti || []; // Access the novosti array

          // Set the news data with the fetched news
          setNewsData(newsList.map((item, index) => ({ id: index, ...item }))); // Assuming each news item has unique properties
        } else {
          setMessage("Dokument ne postoji!"); // Postavi poruku
        }
      } catch (error) {
        setMessage("Došlo je do greške prilikom dohvata novosti."); // Postavi poruku o grešci
      } finally {
        setLoading(false);
      }
    };

    fetchNewsData();
  }, []);

  // Function to format the date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
  };

  const news = slug ? newsData.find((item) => item.slug === slug) : null;

  if (loading) {
    return <div className="center">Učitavanje...</div>;
  }

  if (!news) {
    return <div className="center">Novost nije pronađena!</div>;
  }

  return (
    <>
      <li className="novosti-box">
        <Link to={`/novosti/${news.slug}`}>
          <div className="novosti-box-link">
            <div className="novosti-img">
              {news.mainImage ? (
                <img
                  src={news.mainImage}
                  alt={news.imageAlt}
                  style={{ userSelect: "none" }}
                  loading="lazy"
                />
              ) : (
                <div>Slika nije dostupna.</div>
              )}

              {/*   <div className="novosti-btn">Više...</div> */}
            </div>
            <div className="novosti-info">
              <span>#{news.category}</span>
              <h3>{news.title || "Naslov nije dostupan"}</h3>
              <span>{formatDate(news.date)}</span>
            </div>
          </div>
        </Link>
      </li>
      {message && <div className="error-message">{message}</div>}{" "}
      {/* Prikaz poruke */}
    </>
  );
};

export default News;
